import React, { useState } from 'react';

import { updateStateAttribute } from '../helpers/helper';
import { useAppSelector } from '../hooks/hooks';
import './CLocalizedText.css';

interface Props {
  dictKey: string;
  inline?: boolean;
  collapsible?: boolean;
}

interface State {
  isExpanded: boolean;
}

/**
 * To return as string, use <CLocalizedText .../> as unknown as string.
 * @param props
 * @constructor
 */
export default function CLocalizedText(props: Props) {
  const { localeDict } = useAppSelector((state) => state.dashboard);
  const [state, setState] = useState<State>({ isExpanded: false });
  const classNames = ['localized-text'];

  if (props.inline) {
    classNames.push('inline');
  }

  return (
    <>
      {props.collapsible && !state.isExpanded ? (
        <div className={'inline-localized-text'}>
          <div
            className={[...classNames, 'inline'].join(' ')}
            dangerouslySetInnerHTML={{
              __html: localeDict?.[props.dictKey]
                ? localeDict?.[props.dictKey].split('<br>').slice(0, 1).toString()
                : `-- ${props.dictKey} --`
            }}
          />
          {props.collapsible && (
            <a
              onClick={() => {
                updateStateAttribute<State>({ isExpanded: true }, setState);
              }}
              className={'inline-localized-text inline'}
            >
              &nbsp;&nbsp;
              <CLocalizedText dictKey={'globalReadMore'} />
            </a>
          )}
        </div>
      ) : (
        <span>
          <div
            className={classNames.join(' ')}
            dangerouslySetInnerHTML={{
              __html: localeDict?.[props.dictKey] ?? `-- ${props.dictKey} --`
            }}
          />
          {props.collapsible && (
            <>
              <br />
              <a
                onClick={() => {
                  updateStateAttribute<State>({ isExpanded: false }, setState);
                }}
              >
                <CLocalizedText dictKey={'globalReadLess'} />
              </a>
            </>
          )}
        </span>
      )}
    </>
  );
}
